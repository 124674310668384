import PropTypes from 'prop-types';
import { useRef, useState, useCallback } from 'react';

// @mui
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
// components
import Iconify from 'src/components/iconify';
import ChatService from 'src/services/chat';
import { ROLES } from 'src/_mock/_roles';

// ----------------------------------------------------------------------

// in charge of uploading the messages to the db
export default function ChatMessageInput({
  //
  disabled,
  chatID,
  setMessages,
  user,
}) {
  const fileRef = useRef(null);
  const [message, setMessage] = useState('');

  const handleChangeMessage = useCallback((event) => {
    const { value } = event.target;
    if (value.length > 512) {
      return false;
    }
    setMessage(event.target.value);
    return true;
  }, []);

  const handleSendMessage = useCallback(
    async (event) => {
      try {
        if (event.key === 'Enter' || event.type === 'click') {
          if (message) {
            if (chatID) {
              // await sendMessage(chatID, messageData);
              const messageObj = {
                body: message,
                id: chatID,
              };
              const nextMessage = {
                body: message,
                sent: Date.now(),
                sent_by: user.role === ROLES.AGENT || user.role === ROLES.ADMIN ? 'A' : 'C',
              };

              setMessages((prevMessages) => [...prevMessages, nextMessage]);

              ChatService.sendMessage(messageObj).catch((error) => {
                nextMessage.status = 'error';
                setMessages((prevMessages) => {
                  prevMessages.pop();
                  return [...prevMessages, nextMessage];
                });
              });
            }
          }
          setMessage('');
        }
      } catch (error) {
        console.error(error);
      }
    },
    [message, chatID, setMessages, user]
  );

  return (
    <>
      <InputBase
        value={message}
        onKeyUp={handleSendMessage}
        onChange={handleChangeMessage}
        placeholder="Mensaje"
        disabled={disabled}
        endAdornment={
          <Stack direction="row" sx={{ flexShrink: 0 }}>
            <IconButton onClick={handleSendMessage}>
              <Iconify icon="mynaui:send" name="send" />
            </IconButton>
          </Stack>
        }
        sx={{
          px: 1,
          height: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      />

      <input type="file" ref={fileRef} style={{ display: 'none' }} />
    </>
  );
}

ChatMessageInput.propTypes = {
  disabled: PropTypes.bool,
  chatID: PropTypes.string,
  setMessages: PropTypes.func,
  user: PropTypes.object,
};
