import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { io } from 'socket.io-client';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { ROLES } from 'src/_mock/_roles';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  // connects with the socket and sets notifications for new documents
  const connectSocket = useCallback(
    (email,role) => {
      if (socket) {
        socket.disconnect();
      }
      const namespace = role === 'agent' || role === 'admin' ? 'agents' : 'clients';
      const newSocket = io(`${process.env.REACT_APP_URL_SOCKET}${namespace}`);
      setSocket(newSocket);

      newSocket.on('connect', () => {
        newSocket.emit('user_conected', email);
      });
      // recieve document alerts
      newSocket.on('data', (data) => {
        enqueueSnackbar(data.message, {
          variant: data.variant,
          action: () => (
            <Button
              type="button"
              onClick={() => {
                if (data.role === ROLES.AGENT) {
                  if (window.location.pathname === paths.agents.client.select(data.id)) {
                    window.location.reload();
                  } else {
                    router.push(paths.agents.client.select(data.id));
                  }
                } else if (data.role === ROLES.CLIENT) {
                  if (window.location.pathname === paths.clients.files) {
                    window.location.reload();
                  } else {
                    router.push(paths.clients.files);
                  }
                }
              }}
            >
              <p style={{ color: 'white', margin: '0px', padding: '0px' }}>Ver</p>
            </Button>
          ),
        });
      });
    }, // dont add socket to the dependencies below
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router, enqueueSnackbar]
  );

  // disconects the socket
  const disconnectSocket = useCallback(() => {
    if (socket) {
      // socket.emit('user_disconected');
      socket.disconnect();
      setSocket(null);
    }
  }, [socket]);

  // recives new messages, only active when the chat window is open
  const recieveMessages = useCallback(
    (setMessages, user_id = null) => {
      if (socket && !socket.hasListeners('recive_message')) {
        socket.on('recive_message', (data) => {
          const { message } = data;
          // update the messages if the is no user id (the message comes from the agent) or if its not the same as user_id (the agent is inside the chat of another client)
          if (!data.user_id || data.user_id === user_id) {
            setMessages((prevMessages) => [...prevMessages, message]);
          }
        });
      }
    },
    [socket]
  );
  // for the agent to know when a client sends him messages with an orange dot, only active if the agent is in the list of users page
  const recieveNotifications = useCallback(
    (table, setTable) => {
      if (socket && !socket.hasListeners('unread_messages')) {
        socket.on('unread_messages', (email) => {
          if (email) {
            const newTable = table.map((entry) => {
              if (entry.email === email) {
                return { ...entry, message: true };
              }
              return entry;
            });
            setTable(newTable);
          }
        });
      }
    },
    [socket]
  );
  // for the agent and client to know if they got a message while the chat is closed
  // always active, for the agent through the header, for the client through the whatsapp component
  const recieveMessageNotifications = useCallback(
    (open = null, setOpen = null, user_id = null, showBadge = null) => {
      if (socket && !socket.hasListeners('message_notification')) {
        socket.on('message_notification', (data) => {
          // after the && is to recive notifications from other users when inside the chat of another one
          if ((open === false || open === null) && (!user_id || (data.id && user_id !== data.id))) {
            enqueueSnackbar(data.message, {
              variant: 'info',
              action: () => (
                <Button
                  type="button"
                  onClick={() => {
                    if (data.role === ROLES.AGENT) {
                      if (window.location.pathname === paths.agents.client.select(data.id)) {
                        window.location.reload();
                      } else {
                        router.push(paths.agents.client.select(data.id));
                      }
                    } else if (data.role === ROLES.CLIENT) {
                      showBadge(false);
                      setOpen(true);
                    }
                  }}
                >
                  <p style={{ color: 'white', margin: '0px', padding: '0px' }}>Ver</p>
                </Button>
              ),
            });
          }
          if (showBadge && open === false) {
            showBadge(true);
          }
        });
      }
    },
    [router, enqueueSnackbar, socket]
  );
  // for the agent to know if a client finished a questionary, always active
  const reciveQuestionaryFinished = useCallback(() => {
    if (socket && !socket.hasListeners('questionary_finished')) {
      socket.on('questionary_finished', (data) => {
        enqueueSnackbar(data.message, {
          variant: 'info',
          action: () => (
            <Button
              type="button"
              onClick={() => {
                if (window.location.pathname === paths.agents.client.select(data.id)) {
                  window.location.reload();
                } else {
                  router.push(paths.agents.client.select(data.id));
                }
              }}
            >
              <p style={{ color: 'white', margin: '0px', padding: '0px' }}>Ver</p>
            </Button>
          ),
        });
      });
    }
  }, [router, enqueueSnackbar, socket]);

  const reciveUnreadNotifications = useCallback(
    (setNotifications) => {
      if (socket && !socket.hasListeners('notifications_to_front')) {
        socket.on('notifications_to_front', (data) => {
          setNotifications(data);
        });
      }
      if (socket) {
        socket.emit('notifications_request_from_front');
      }
    },
    [socket]
  );

  const markReadNotifications = useCallback(
    (notificationsToRead) => {
      if (socket) {
        socket.emit('read_notification', notificationsToRead);
      }
    },
    [socket]
  );
  // To update list of users in the agent view
  const updateListOnDocumentUpoload = useCallback(
    (table, setTable) => {
      if (socket && !socket.hasListeners('document_uploaded')) {
        socket.on('document_uploaded', (row) => {
          if (row) {
            const newTable = table.map((entry) => {
              if (entry.email === row.email) {
                return row;
              }
              return entry;
            });
            setTable(newTable);
          }
        });
      }
    },
    [socket]
  );

  const reciveClientUpdateError = useCallback(
    () => {
      if (socket && !socket.hasListeners('client_update_error_to_agent')) {
        socket.on('client_update_error_to_agent', (data) => {
          enqueueSnackbar(data.title, {
            variant: 'warning',
          });
        });
      }
      if (socket) {
        socket.emit('data_update_status_to_agent');
      }
    },
    [socket,enqueueSnackbar]
  );
  // a function to re-render the files from the back, it needs a state variable and its function
  // and you just need to add the variable to the dependency array of the useEffect in the component
  // its important to make one of this for every component you want to re-render because one component
  // could end up re-rendering another without having to
  const reRenderFilesComponent = useCallback(
    (setUpdate, update) => {
      if (socket && !socket.hasListeners('update_files')) {
        socket.on('update_files', () => {
          setUpdate(!update);
        });
      }
    },
    [socket]
  );
  // to re-render when the agent asigns a solution to the client without him having finished the
  // questionary
  const reRenderGuard = useCallback(
    (setUpdate, update) => {
      if (socket && !socket.hasListeners('update_guard')) {
        socket.on('update_guard', () => {
          setUpdate(!update);
        });
      }
    },
    [socket]
  );
  // to re-render when the client cancels a recurrent payment
  const reRenderPaymentsComponent = useCallback(
    (setUpdate, update) => {
      if (socket && !socket.hasListeners('update_pay')) {
        socket.on('update_pay', () => {
          setUpdate(!update);
        });
      }
    },
    [socket]
  );

  const value = useMemo(
    () => ({
      socket,
      connectSocket,
      disconnectSocket,
      recieveMessages,
      recieveNotifications,
      recieveMessageNotifications,
      reciveQuestionaryFinished,
      reciveUnreadNotifications,
      markReadNotifications,
      reRenderFilesComponent,
      reRenderGuard,
      updateListOnDocumentUpoload,
      reciveClientUpdateError,
      reRenderPaymentsComponent,
    }),
    [
      socket,
      connectSocket,
      disconnectSocket,
      recieveMessages,
      recieveNotifications,
      recieveMessageNotifications,
      reciveQuestionaryFinished,
      reciveUnreadNotifications,
      markReadNotifications,
      reRenderFilesComponent,
      reRenderGuard,
      updateListOnDocumentUpoload,
      reciveClientUpdateError,
      reRenderPaymentsComponent,
    ]
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

SocketProvider.propTypes = {
  children: PropTypes.node,
};
