import React, { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/clients/layout';

// components
import { LoadingScreen } from 'src/components/loading-screen';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/clients/app'));
const PageFinancial = lazy(() => import('src/pages/clients/financial'));
const PageFiles = lazy(() => import('src/pages/clients/files'));
const ProfilePage = lazy(() => import('src/pages/clients/profile'));
const KYCPage = lazy(() => import('src/pages/clients/kyc'));
const PaymentSuccess = lazy(() => import('src/sections/clients/payment/payment-success'));
const PaymentError = lazy(() => import('src/sections/clients/payment/payment-error'));
const PaymentsView = lazy(() => import('src/pages/clients/payments-page'));
const PagePayment = lazy(() => import('src/pages/clients/payment'));



// ----------------------------------------------------------------------

export const clientsRoutes = [
  {
    path: 'clients',
    element: (
      <AuthGuard>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        index: true,
        element: (
          <DashboardLayout>
            <IndexPage />
          </DashboardLayout>
        ),
      },
      {
        path: 'financial',
        element: (
          <DashboardLayout>
            <PageFinancial />
          </DashboardLayout>
        ),
      },
      {
        path: 'files',
        element: (
          <DashboardLayout>
            <PageFiles />
          </DashboardLayout>
        ),
      },
      {
        path: 'profile',
        element: (
          <DashboardLayout>
            <ProfilePage />
          </DashboardLayout>
        ),
      },
      {
        path: 'questionnaire',
        element: (
          <AuthModernCompactLayout customMaxWidth={1000}>
            <KYCPage />
            <Typography fontSize={9} marginTop={3} color="#9e9e9e">
            La información aportada por el Usuario a través del Portal del Deudor tiene carácter confidencial, comprometiéndose Diglo Servicer Company 2021, S.L. a no divulgarla a terceros (se excluye del término “terceros” a los representantes, empleados y proveedores de DIGLO, así como al titular de la deuda del Usuario) y a adoptar las medidas necesarias para prevenir cualquier uso, revelación o publicación no autorizada de la misma. La información que facilite será tratada por DIGLO como servicer designado por el titular de la deuda. Por tanto, de acuerdo con la normativa de protección de datos, DIGLO actúa como encargado del tratamiento, de forma que tratará la información que facilite con el único fin de analizar, en nombre y por cuenta del titular de la deuda, las posibles alternativas recuperatorios asociadas a la deuda del Usuario.            
            </Typography>
          </AuthModernCompactLayout>
        ),
      },
      {
        path: 'pay-aproved',
        element: (
          <AuthModernCompactLayout customMaxWidth={600}>
            <PaymentSuccess />
          </AuthModernCompactLayout>
        ),
      },
      {
        path: 'pay-error',
        element: (
          <AuthModernCompactLayout customMaxWidth={600}>
            <PaymentError />
          </AuthModernCompactLayout>
        ),
      },
      /* {
        path: 'payments',
        element: (
          <DashboardLayout>
            <PaymentsView />
          </DashboardLayout>
        ),
      }, */
      {
        path: 'payment',
        element: (
          <AuthGuard>
          <AuthModernCompactLayout customMaxWidth={1000}>
          <PagePayment />
          </AuthModernCompactLayout>
          </AuthGuard>
        ),
      },
    ],
  },
];
