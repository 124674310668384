import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSettingsContext } from 'src/components/settings';

// routes
import ClientService from 'src/services/client';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { ROLES } from 'src/_mock/_roles';
//
import { useAuthContext } from '../hooks';
import { useSocket } from '../context/socket-context';

// ----------------------------------------------------------------------

const PATHS = {
  jwt: paths.auth.login,
  admin: paths.agents.root,
  agent: paths.agents.root,
  client: paths.clients.root,
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter();
  const settings = useSettingsContext();

  const { authenticated, method, user } = useAuthContext();
  const location = useLocation();
  const [checked, setChecked] = useState(false);

  const [reRender, setReRender] = useState();
  const { socket, reRenderGuard } = useSocket();

  // this is to re-render the component when the agent asigns a solution and the
  // client didnt have one before
  useEffect(() => {
    reRenderGuard(setReRender, reRender);
    return () => {
      if (socket) {
        socket.off('update_guard');
      }
    };
  }, [socket, reRenderGuard, reRender]);

  const validateSolution = useCallback(() => {
    if (user && user.role === ROLES.CLIENT) {
      ClientService.validateSolution()
      .then((response) => {
        if (response === null) {
          settings.onFiles(false);
        } else {
          settings.onFiles(true);
        }
      })
      .catch((error) =>{
        console.log("error validando solucion")
      });
    }
  }, [settings, user]);

  const check = useCallback(() => {
    if (!authenticated) {
      const loginPath = PATHS[method];
      router.replace(loginPath);
      return;
    }

    // Validate solution
    validateSolution();

    // Mapeo de roles y rutas
    const rolePathMap = {
      agent: 'agents',
      admin: 'agents',
      client: 'clients',
    };

    const expectedRolePath = rolePathMap[user.role];
    if (location.pathname.indexOf(expectedRolePath) !== 1) {
      router.replace(PATHS[user.role]);
    }

    setChecked(true);
  }, [authenticated, method, router, user, location, validateSolution]);
  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRender]);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
