// utils
import axios from 'src/utils/axios';
import Auth from 'src/services/auth';
import LoginRedirect from 'src/utils/redirect-login';
// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------
export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const setSession = async (accessToken, user) => {
  if (accessToken) {
    // add accessToken in axios
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;

    if (!user) {
      try {
        user = await Auth.login();
      } catch (error) {
        localStorage.clear();
        LoginRedirect('Ocurrió un error inesperado, por favor intentelo de nuevo.');
        return;
      }
    }

    localStorage.setItem('user', JSON.stringify(user));
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    delete axios.defaults.headers.common.Authorization;
  }
};
