import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
import ChatService from 'src/services/chat';
import { useSocket } from 'src/auth/context/socket-context';
import { useTheme } from '@emotion/react';

// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Alert, Box, CircularProgress } from '@mui/material';
import EmptyContent from 'src/components/empty-content/empty-content';
import ChatHeaderDetail from '../chat-header-detail';
import ChatInputList from '../chat-input-list';

// ----------------------------------------------------------------------
export default function ChatView({
  email,
  open = null,
  setChatOpen = null,
  user_id = null,
  showBadge = null,
}) {

  const [chatData, setChatData] = useState(false);
  const { socket, recieveMessageNotifications } = useSocket();
  const [alertMsg, setAlertMsg] = useState();

  const themee = useTheme();

  const getChats = useCallback(async (userMail) => {
    try {
      // use the email recieved and get the full conversation
      const data = await ChatService.getChat({ email: userMail });
      setChatData(data);
    } catch (error) {
      setAlertMsg('Ocurrió un error al cargar los mensajes');
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment

    if (email) {
      getChats(email);
    }

    // turns of the message notifications from this user and turns them back on when closing the chat
    if (socket) {
      socket.off('message_notification');
      recieveMessageNotifications(open, setChatOpen, user_id, showBadge);
    }
    return () => {
      if (socket) {
        socket.off('message_notification');
        recieveMessageNotifications(open, setChatOpen, user_id, showBadge);
      }
    };
  }, [email, getChats, recieveMessageNotifications, open, setChatOpen, socket, user_id, showBadge]);

  const renderHead = chatData && (
    <Stack
      direction="row"
      alignItems="center"
      flexShrink={0}
      sx={{ pr: 1, pl: 2.5, py: 1, minHeight: 72, backgroundColor: themee.palette.primary.main }}
    >
      <ChatHeaderDetail recieverUser={chatData.reciever_data} setOpen={setChatOpen} />
    </Stack>
  );
  const renderMessages = chatData && (
    <Stack
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <ChatInputList
        messageList={chatData.messages}
        reciever={chatData.reciever_data}
        chatID={chatData.chat_id}
        user_id={user_id}
      />
    </Stack>
  );
  return (
    <>
      {!!alertMsg && <Alert severity="error">{alertMsg}</Alert>}

      {/* eslint-disable-next-line no-nested-ternary */}
      {chatData ? (
        <Stack component={Card} direction="row" sx={{ height: '72vh' }}>
          <Stack
            sx={{
              width: 1,
              height: 1,
              overflow: 'hidden',
            }}
          >
            {renderHead}
            <Stack
              direction="row"
              sx={{
                width: 1,
                height: 1,
                overflow: 'hidden',
                borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            >
              {renderMessages}
            </Stack>
          </Stack>
        </Stack>
      ) : // eslint-disable-next-line react/destructuring-assignment
        !email ? (
          <Stack component={Card} direction="row" sx={{ height: '50vh' }}>
            <EmptyContent title="El cliente no se ha registrado" />
          </Stack>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: "center", marginTop:5, alignItems:"center", height:250 }}>
            <CircularProgress />
          </Box>
        )}

    </>
  );
}

ChatView.propTypes = {
  email: PropTypes.string,
  open: PropTypes.bool,
  setChatOpen: PropTypes.func,
  user_id: PropTypes.string,
  showBadge: PropTypes.func,
};
