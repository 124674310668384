const version = "0.2.0"
// DONT TOUCH THIS FILE PLEASE

/* eslint-disable */
function updateVersion() {
    let parts = version.toString().split('.');
    let major = parseInt(parts[0]);
    let minor = parseInt(parts[1]);
    let patch = parseInt(parts[2]);
    
    
    if (patch === 9) {
        minor++;
        patch = 0;
    } else {
        patch++;
    }
    if (minor === 9) {
        major++;
        minor = 0;
    } 

    
    return (major + '.' + minor + '.' + patch) ;
    
    
}
module.exports = { version, updateVersion };



// Test the function
/* updateVersion()
console.log("Initial version:", version); 
updateVersion()
console.log("Updated version:", version); 
updateVersion()
console.log("Updated version:", version); 
updateVersion()
console.log("Updated version:", version); 
updateVersion()
console.log("Updated version:", version); 
updateVersion()
console.log("Updated version:", version); 
updateVersion()
console.log("Updated version:", version); 
updateVersion()
console.log("Updated version:", version); 
updateVersion()
console.log("Updated version:", version); 
updateVersion()
console.log("Updated version:", version); 
updateVersion()
console.log("Updated version:", version); 
updateVersion()
console.log("Updated version:", version);  */

