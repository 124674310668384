import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import WhatsAppContainer from 'src/components/chatbot/whatsapp';
import { useEffect, useState } from 'react';
import ClientService from 'src/services/client';

// components
import { useSettingsContext } from 'src/components/settings';
//
import KYCSnackbar from 'src/sections/clients/kyc/kyc-snackbar';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import PowerBy from '../../components/power-by/power-by';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [isChatOpen, setChatOpen] = useState(false);


  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';


  const renderNavMini = (
    <NavMini
      unnax={settings.unnax}
      unnaxAfter={settings.unnaxAfter}
      files={settings.files}
      refinance={settings.refinance}
    />
  );

  const renderHorizontal = (
    <NavHorizontal
      unnax={settings.unnax}
      unnaxAfter={settings.unnaxAfter}
      files={settings.files}
      refinance={settings.refinance}
    />
  );

  const renderNavVertical = (
    <NavVertical
      openNav={nav.value}
      onCloseNav={nav.onFalse}
      unnax={settings.unnax}
      unnaxAfter={settings.unnaxAfter}
      files={settings.files}
      refinance={settings.refinance}
    />
  );

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
        <PowerBy />
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
        <PowerBy />
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} isChatOpen={isChatOpen} setChatOpen={setChatOpen} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>
          {children}
          <KYCSnackbar />
          <PowerBy />
        </Main>
      </Box>
      <WhatsAppContainer isChatOpen={isChatOpen} setChatOpen={setChatOpen} />
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
