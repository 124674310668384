import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSocket } from 'src/auth/context/socket-context';
import Badge from '@mui/material/Badge';
import {
  Box,
  Button,
  Dialog,
  Fade,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Typography,
} from '@mui/material';
import { ChatView } from 'src/sections/agents/chat/view';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from '../iconify/iconify';

export default function WhatsAppContainer({ isChatOpen = false, setChatOpen }) {
  const { user } = useAuthContext()
  const [openDialogCalendar, setOpenDialogCalendar] = useState(false);


  const [position, setPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  const { socket, recieveMessageNotifications } = useSocket();

  const [showBadge, setShowBadge] = useState(false)

  const ACTIONS = [
    /*
        {
            key: "whatsapp",
            icon: <Iconify icon="ic:baseline-whatsapp" width={30} color="primary" />,
            name: 'Whatsapp'
        },
        {
            key: "call",
            icon: <Iconify icon="ic:round-phone" width={30} color="primary" />,
            name: 'Llamar'
        },
        {
            key: "calendar",
            icon: <Iconify icon="ic:baseline-calendar-month" width={30} color="primary" />,
            name: 'Agendar llamada'
        },
        */
    user
      ? {
        key: 'chat',
        icon: <Iconify icon="fluent:chat-12-regular" width={30} color="primary" />,
        name: 'Chatea con tu agente',
      }
      : '',
  ];

  const handlers = {
    calendar: setOpenDialogCalendar,
  };

  const handleOpenDialog = (dialogName) => {
    handlers[dialogName](true);
  };

  const handleCloseDialog = (dialogName) => {
    handlers[dialogName](false);
  };

  const onAction = (action) => {
    if (action === 'whatsapp') {
      const phoneNumber = '+525534955438';
      const message = 'Hola, estaría interesado en ...';

      const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
        message
      )}`;

      window.open(whatsappLink, '_blank');

      // } else if(action === "call"){
    } else if (action === 'calendar') {
      handleOpenDialog('calendar');
    } else if (action === 'chat') {
      handleChatButtonClick();
    }
  };

  const fabStyle = {
    position: 'fixed',
    bottom: 30,
    right: 30,
  };

  useEffect(() => {
    const updatePosition = () => {
      if (buttonRef.current) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        setPosition({
          top: buttonRect.top - 500,
          left: buttonRect.left - 300,
        });
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, []);

  // starts the message notifications for the client
  useEffect(() => {
    recieveMessageNotifications(isChatOpen, setChatOpen, null, setShowBadge);
    return () => {
      if (socket) {
        socket.off('message_notification');
      }
    };
  }, [isChatOpen, recieveMessageNotifications, socket, setChatOpen,]);

  const handleChatClose = () => {
    setChatOpen(false);
  };
  const handleChatButtonClick = () => {
    setShowBadge(false)
    setChatOpen(true);
  };

   // eslint-disable-next-line no-nested-ternary
   const [reachUsDialog, setReachDialog] = useState(sessionStorage.getItem("reachUsDialog")!==null?sessionStorage.getItem("reachUsDialog") !== "undefined"? JSON.parse(sessionStorage.getItem("reachUsDialog")):undefined : false)
   const setReachUsDialog = useCallback(() => {
     setReachDialog(true)
     sessionStorage.setItem("reachUsDialog",(true))
   },[])
 
   const finishReachUsDialog = useCallback(() => {
     setReachDialog(undefined)
     sessionStorage.setItem("reachUsDialog",(undefined))
   },[])
 
   const setReachUsDialogTimer = useCallback(() => { 
     if(reachUsDialog === false){
       const timer = setTimeout(() => {
         setReachUsDialog();
         const timerTwo = setTimeout(() => {
           finishReachUsDialog();
         }, 20000);
       }, 10000);
     }
     
   },[setReachUsDialog,reachUsDialog,finishReachUsDialog])
 
  useEffect(() =>{
    if (reachUsDialog === false){
      setReachUsDialogTimer()

    }
    
  },[reachUsDialog,setReachUsDialogTimer,setReachDialog])
  return (
    <>
    <Stack position="relative">
    
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={fabStyle}
        icon={
          <Badge color="error" badgeContent=" " invisible={!showBadge}>
            <Iconify icon="mdi:support" width={40} />
          </Badge>

        }
        ref={buttonRef}
      >
        {user
          ? ACTIONS.map((action) => (
            <SpeedDialAction
              key={action.key}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => onAction(action.key)}
            />
          ))
          : ''}
      </SpeedDial>
      <Fade in={reachUsDialog} timeout={1000} style={{ opacity: 0.9 }}>
      <Box sx={{backgroundColor:"primary.light", fontWeight:"bold", color:"white",borderRadius:"5px",position:"fixed",right: '50px', bottom:"100px",
      padding:0 }} padding={2} display="flex"
      >
       <Typography padding={1.5} >¿Necesitas ayuda? Contáctanos!</Typography> 
       <Button sx={{minWidth:"10px",minHeight:"10px", padding:"0", marginLeft:"auto",marginRight:"5px", marginBottom:"auto"}}
       onClick={()=>{finishReachUsDialog()}}
       >X</Button>
    </Box>
    </Fade>
      <Dialog
        open={isChatOpen}
        onClose={handleChatClose}
        sx={{
          position: 'fixed',
          '& .MuiPaper-root': {
            width: '100%',
          },
          '@media (min-width: 463px)': {
            top: position.top,
            left: position.left,
            transform: 'translate(-20%, 0%)',
          },
          '@media (max-width: 463px)': {
            top: position.top - 150,
          },
        }}
        hideBackdrop
        TransitionComponent={Fade}
        TransitionProps={{
          timeout: 600,
        }}
      >
        {user && user?.email && (
          <ChatView email={user.email} open={isChatOpen} setChatOpen={setChatOpen} showBadge={setShowBadge} />
        )}
      </Dialog>
      </Stack>
    </>
    /* <Fab color="success" aria-label="add" sx={fabStyle} onClick={handleFabClick}>
            <WhatsAppIcon sx={{ fontSize: 30 }}/>
        </Fab> */
  );
}

WhatsAppContainer.propTypes = {
  isChatOpen: PropTypes.bool,
  setChatOpen: PropTypes.func,
};
