import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

// ----------------------------------------------------------------------

// auth
const LoginPage = lazy(() => import('src/pages/auth/login'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));
const ForgotPasswordModernPage = lazy(() => import('src/pages/auth/forgot-password'));
const NewPasswordModernPage = lazy(() => import('src/pages/auth/new-password'));
const SendPasswordView = lazy(() => import('src/sections/auth/send-password-view'));
const LegalText = lazy(()=> import("src/sections/clients/privacy-legal/legal"))
const PrivacyText = lazy(()=> import("src/sections/clients/privacy-legal/privacy"))
// ----------------------------------------------------------------------

const auth = {
  path: '',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <LoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'signup',
      element: (
        <AuthClassicLayout>
          <RegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <AuthModernCompactLayout customMaxWidth={500}>
          <ForgotPasswordModernPage />
        </AuthModernCompactLayout>
      ),
    },
    {
      path: 'new-password',
      element: (
        <AuthModernCompactLayout customMaxWidth={600}>
          <NewPasswordModernPage />
        </AuthModernCompactLayout>
      ),
    },
    {
      path: 'send-password',
      element: (
        <AuthModernCompactLayout customMaxWidth={600}>
          <SendPasswordView />
        </AuthModernCompactLayout>
      ),
    },
    {
      path: 'legal',
      element: (
        <AuthModernCompactLayout customMaxWidth={700}>
          <LegalText />
        </AuthModernCompactLayout>
      ),
    },
    {
      path: 'privacy',
      element: (
        <AuthModernCompactLayout customMaxWidth={700}>
          <PrivacyText />
        </AuthModernCompactLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: '',
    children: [auth],
  },
];
