import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import { Stack, Typography } from '@mui/material';
// routes
import { RouterLink } from 'src/routes/components';
//
import Iconify from '../../iconify';
//
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';

// ----------------------------------------------------------------------

export default function NavItem({ item, open, depth, active, config, externalLink, ...other }) {
  const { title, path, icon, info, children, disabled, caption, roles, tooltip } = item;

  const subItem = depth !== 1;

  const [openName, setOpenName] = useState(false);

  const renderContent = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <StyledItem
        disableGutters
        disabled={disabled}
        active={active}
        depth={depth}
        config={config}
        {...other}
      >
        <>
          {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

          {subItem && (
            <StyledIcon size={config.iconSize}>
              <StyledDotIcon active={active} />
            </StyledIcon>
          )}
        </>

        {!(config.hiddenLabel && !subItem) && (
          <ListItemText
            primary={title}
            secondary={
              caption ? (
                <Tooltip title={caption} placement="top-start">
                  <span>{caption}</span>
                </Tooltip>
              ) : null
            }
            primaryTypographyProps={{
              noWrap: true,
              typography: 'body2',
              fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
            }}
            secondaryTypographyProps={{
              noWrap: true,
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
        )}

        {info && (
          <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
            {info}
          </Box>
        )}

        {!!children && (
          <Iconify
            width={16}
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ ml: 1, flexShrink: 0 }}
          />
        )}
      </StyledItem>

      {tooltip && (
        <Tooltip
          arrow
          title={tooltip}
          open={openName}
          onClose={() => setOpenName(false)}
        >
          <Iconify
            icon="mdi:information-outline"
            sx={{mb: 1}}
            width={20}
            onMouseEnter={() => setOpenName(true)}
            onMouseLeave={() => setOpenName(false)}
          />
        </Tooltip>
      )}
    </div>
  );

  // Hidden item by role
  if (roles && !roles.includes(`${config.currentRole}`)) {
    return null;
  }

  // External link
  if (externalLink)
    return (
      <Link
        href={path}
        target="_blank"
        rel="noopener"
        underline="none"
        color="inherit"
        sx={{
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    );

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link
      component={RouterLink}
      href={disabled ? null : path}
      underline="none"
      color="inherit"
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>
  );
}

NavItem.propTypes = {
  active: PropTypes.bool,
  config: PropTypes.object,
  depth: PropTypes.number,
  externalLink: PropTypes.bool,
  item: PropTypes.object,
  open: PropTypes.bool,
};
