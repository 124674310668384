/* eslint-disable class-methods-use-this */
import axios, { endpoints } from 'src/utils/axios';
import { setSession } from 'src/auth/context/utils';

const interceptorCorrect = (response) => response;
// eslint-disable-next-line consistent-return
const interceptorError = async (error) => {
  console.log('ERROOOOOOOR', error);
  if (error.response.status === 402 || error.response.status === 401) {
    sessionStorage.setItem('redirected', true);
    sessionStorage.setItem('redirectMessage', error.response.data.error);
    sessionStorage.setItem('redirectSeverity', 'error');
    console.log('ERROR 401');
    await setSession()
    /* eslint-disable no-restricted-globals */
    location.reload();
  } else if (error.response.status === 501) {
    console.log('ERROR 501')
  } else {
    return Promise.reject(error);
  }
};

class ClientService {
  static axiosInstance;

  static createAxios = () => {
    ClientService.axiosInstance = axios.create({
      withCredentials: true,
      defaults: {
        headers: axios.defaults.headers,
      },
    });

    ClientService.axiosInstance.interceptors.response.use(interceptorCorrect, interceptorError);

    return ClientService.axiosInstance;
  };

  static async get(userId) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.root(userId);
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async me() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.me;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async profile() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.profile;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async documents() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.documents;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async validateSolution() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.validateSolution;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async questions() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.questions;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async answers(answers) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.answers;
    const response = await axiosInstance.post(endpoint, answers);

    return response.data;
  }

  static async updateProfile(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.profile;
    const response = await axiosInstance.put(endpoint, data);

    return response.data;
  }

  static async updateTelephones(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.telephones;
    const response = await axiosInstance.put(endpoint, data);

    return response.data;
  }

  static async changePassword(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.changePassword;
    const response = await axiosInstance.put(endpoint, data);

    return response.data;
  }

  static async availableSolutions(userId) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.availableSolutions(userId);
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async availableDocuments(userId) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.availableDocuments(userId);
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async changeSolution(userId, newSolution) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.changeSolution(userId);
    const response = await axiosInstance.put(endpoint, newSolution);

    return response.data;
  }

  static async addDocument(userId, newDocument) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.addDocument(userId);
    const response = await axiosInstance.post(endpoint, {
      document: newDocument,
    });

    return response.data;
  }

  static async list(prms) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.list;
    const response = await axiosInstance.get(endpoint, prms);

    return response.data;
  }

  static async metrics() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.metrics;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async getUnnaxToken() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.unnax;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async getUnnax(endpoint, prms, headers) {
    const axiosInstance = this.createAxios();
    const response = await axiosInstance.get(endpoint, prms, { headers });
    return response.data;
  }

  static async getInfoToken() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.getInfoToken;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async payment(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.payment;
    const response = await axiosInstance.post(endpoint,data);

    return response.data;
  }

  static async getContracts() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.getContracts;
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async bankAggregation() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.bankAggregation
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async getFinancial(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.getFinancial
    const response = await axiosInstance.post(endpoint,data);

    return response.data;
  }

  static async getGlobals() {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.globals
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }

  static async getPayments(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.getPayments
    const response = await axiosInstance.post(endpoint,data);

    return response.data;
  }

  static async cancelPayment(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.client.cancelPayment
    const response = await axiosInstance.post(endpoint,data);

    return response.data;
  }
}

export default ClientService;
