import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import AdminDashboardLayout from 'src/layouts/agents';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const ClientsPage = lazy(() => import('src/pages/agents/app'));
const AccountClient = lazy(() => import('src/pages/agents/account'));

const agent = {
  path: '',
  element: (
    <AuthGuard>
      <AdminDashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </AdminDashboardLayout>
    </AuthGuard>
  ),
  children: [
    { element: <ClientsPage />, index: true },
    {
      path: 'client',
      children: [{ path: ':id', element: <AccountClient /> }],
    },
  ],
};

export const agentsRoutes = [
  {
    path: 'agents',
    children: [agent],
  },
];
