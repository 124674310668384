import axios, { endpoints } from 'src/utils/axios';
import { setSession } from 'src/auth/context/utils';

const interceptorCorrect = (response) => response;

/* eslint-disable consistent-return */
const interceptorError = async (error) => {
  console.log(error.response.status);

  if (error.response.status === 402 || error.response.status === 401) {
    sessionStorage.setItem('redirected', true);
    sessionStorage.setItem('redirectMessage', error.response.data.error);
    sessionStorage.setItem('redirectSeverity', 'error');
    console.log('ERROR 401');
    await setSession()
    /* eslint-disable no-restricted-globals */
    location.reload();
  } else if (error.response.status === 501) {
    console.log('ERROR 501')
  } else {
    return Promise.reject(error);
  }
};

class ChatService {
  static axiosInstance;

  static createAxios = () => {
    ChatService.axiosInstance = axios.create({
      withCredentials: true,
      defaults: {
        headers: axios.defaults.headers,
      },
    });

    ChatService.axiosInstance.interceptors.response.use(interceptorCorrect, interceptorError);

    return ChatService.axiosInstance;
  };

  static getChat = async (email) => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.chat.chat;
    const response = await axiosInstance.post(endpoint, email);
    return response.data;
  };

  static sendMessage = async (message) => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.chat.sendMessage;
    const response = await axiosInstance.post(endpoint, message);
    return response.data;
  };

  static markAsRead = async (message) => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.chat.markAsRead;
    const response = await axiosInstance.post(endpoint, message);
    return response.data;
  };
}

export default ChatService;
