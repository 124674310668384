import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import PowerBy from '../../components/power-by/power-by';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children, title }) {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      justifyContent="center"
      sx={{
        mx: 'auto',
        width: 1,
        maxWidth: 500,
        p: { xs: 3, md: 8 },
      }}
    >
      {children}

      <Stack marginTop={5} display={{md:"none"}}>
      <PowerBy />
      </Stack>

    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={5}
      alignItems="center"
      justifyContent="center"
      sx={{
        p: 3,
        overflow: 'hidden',
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      <Box component="img" src="/logo/Diglo.png" sx={{ width: 200, cursor: 'pointer' }} />
      <Stack spacing={3}>
        <Typography variant="h3" textAlign="center">
          {title || '¡Mejora tu situación financiera con nosotros!'}
        </Typography>
        <Typography variant="body2" textAlign="center">
          Estamos aquí para ayudarte, inicia sesión y descubre cómo podemos hacerlo
        </Typography>
      </Stack>
      <Box
        component="img"
        alt="auth"
        src="/assets/images/finance.webp"
        sx={{
          maxWidth: {
            xs: 480,
            lg: 560,
            xl: 720,
          },
        }}
      />
      <PowerBy />
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {mdUp && renderSection}

      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};
