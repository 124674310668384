/* eslint-disable no-else-return */
import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData(unnax, unnaxAfter, files, refinance,  type) {
  const data = useMemo(() => {
    const pathsNav = [
      {
        subheader: '',
        items: [{ title: 'Posición global', path: paths.clients.root, icon: ICONS.banking }],
      },
    ];

    if (files) {
      pathsNav[0].items.push({
        title: 'Mis documentos cargados',
        path: paths.clients.files,
        icon: ICONS.mail,
      });
    } else {
      pathsNav[0].items.push({
        title: 'Mis documentos cargados',
        path: paths.clients.files,
        icon: ICONS.mail,
        disabled: true,
        tooltip: 'Cuando rellene el cuestionario, podrá cargar aquí sus documentos',
      });
    }

    return pathsNav;
  }, [ files]);

  return data;
}
