import axios from 'axios';
// ----------------------------------------------------------------------
const BASE_API = process.env.REACT_APP_URL_API;

const axiosInstance = axios.create({ baseURL: BASE_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject(error)
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: 'https://api-dev-minimal-v510.vercel.app/api/chat',
  api: {
    auth: {
      login: `${BASE_API}/auth/login`,
      getUser: `${BASE_API}/auth/user-exists`,
      verifyData: `${BASE_API}/auth/verify-data`,
      registration: `${BASE_API}/auth/registration`,
      verificationMail: `${BASE_API}/auth/verification-mail`,
      resetPassword: `${BASE_API}/auth/reset-password`,
      digloCreate:  `${BASE_API}/auth/diglo-create`
    },
    documentUrl: {
      get: `${BASE_API}/documents/download-urls`,
      put: `${BASE_API}/documents/upload-url`,
    },
    document: {
      delete: (id) => `${BASE_API}/documents/${id}/delete`,
      approve: (id) => `${BASE_API}/documents/${id}/approve`,
      decline: (id) => `${BASE_API}/documents/${id}/decline`,
      upload: (id) => `${BASE_API}/documents/${id}/upload`,
    },
    client: {
      root: (id) => `${BASE_API}/clients/${id}`,
      me: `${BASE_API}/clients/me`,
      documents: `${BASE_API}/clients/me/documents`,
      questions: `${BASE_API}/clients/me/questions`,
      answers: `${BASE_API}/clients/me/answers`,
      profile: `${BASE_API}/clients/me/profile`,
      telephones: `${BASE_API}/clients/me/telephones`,
      changePassword: `${BASE_API}/clients/me/change-password`,
      validateSolution: `${BASE_API}/clients/me/validate-solution`,
      list: `${BASE_API}/clients/list`,
      metrics: `${BASE_API}/clients/metrics`,
      upsert: `${BASE_API}/clients/upsert`,
      checkout: `${BASE_API}/clients/checkout`,
      unnax: `${BASE_API}/clients/get-unnax-token`,
      addDocument: (id) => `${BASE_API}/clients/${id}/add-document`,
      changeSolution: (id) => `${BASE_API}/clients/${id}/change-solution`,
      availableSolutions: (id) => `${BASE_API}/clients/${id}/available-solutions`,
      availableDocuments: (id) => `${BASE_API}/clients/${id}/available-documents`,
      getInfoToken: `${BASE_API}/clients/info`,
      payment: `${BASE_API}/payment/pay`,
      getContracts: `${BASE_API}/clients/get-contracts`,
      bankAggregation:  `${BASE_API}/clients/bank-aggregation`,
      getFinancial: `${BASE_API}/clients/financial`,
      globals: `${BASE_API}/clients/get-globals`,
      getPayments: `${BASE_API}/clients/get-payments`,
      cancelPayment: `${BASE_API}/clients/cancel-payment`,


    },
    chat: {
      chat: `${BASE_API}/chat/get-chat`,
      sendMessage: `${BASE_API}/chat/send-message`,
      markAsRead: `${BASE_API}/chat/mark-read`,
    },
    catalogs: {
      catalog: `${BASE_API}/catalog/get-catalog`,
    },
    payment: {
      new: `${BASE_API}/payment/new`,
    }
  },
};
