import { m } from 'framer-motion';
import { useState,  useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EmptyContent from 'src/components/empty-content';
// hooks
import { useSocket } from 'src/auth/context/socket-context';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//
import NotificationItem from './notification-item';
// ----------------------------------------------------------------------
export default function NotificationsPopover({ setChatOpen }) {
  const { socket, reciveUnreadNotifications, markReadNotifications } = useSocket();

  const drawer = useBoolean();

  const smUp = useResponsive('up', 'sm');

  const [notifications, setNotifications] = useState();

  const totalUnRead = notifications
    ? notifications.filter((item) => item.read === false).length
    : 0;

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        read: true,
      }))
    );
    markReadNotifications(notifications);
  };

  useEffect(() => {
    reciveUnreadNotifications(setNotifications);

    return () => {
      if (socket) {
        socket.off('notifications_to_front');
      }
    };
  }, [reciveUnreadNotifications, socket]);

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notificaciones
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {
        !smUp && (
          <IconButton onClick={drawer.onFalse}>
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        ) // does nothing, need to see what smup is
      }
    </Stack>
  );
  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications && notifications.length > 0 ? (
          notifications
            .toReversed()
            .filter((item) => item.read === false)
            .map((notification, index) => (
              <NotificationItem
                key={index}
                notification={notification}
                setChatOpen={setChatOpen}
                position={index}
                setNotifications={setNotifications}
                open={drawer}
              />
            ))
        ) : (
          <EmptyContent
            title="Sin notificaciones"
            sx={{
              py: 10,
            }}
          />
        )}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />
        <Divider />
        {renderList}
      </Drawer>
    </>
  );
}

NotificationsPopover.propTypes = {
  setChatOpen: PropTypes.func,
};
