// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '',
  CLIENTS: '/clients',
  AGENTS: '/agents',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    signup: `${ROOTS.AUTH}/signup`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    newPassword: `${ROOTS.AUTH}/new-password`,
    sendPassword: `${ROOTS.AUTH}/send-password`,
    legal: `${ROOTS.AUTH}/legal`,
    privacy: `${ROOTS.AUTH}/privacy`,
  },
  // CLIENTS
  clients: {
    root: ROOTS.CLIENTS,
    financial: `${ROOTS.CLIENTS}/financial`,
    agreements: `${ROOTS.CLIENTS}/agreements`,
    files: `${ROOTS.CLIENTS}/files`,
    onboarding: `${ROOTS.CLIENTS}/onboarding`,
    validation: `${ROOTS.CLIENTS}/validation`,
    verify: `${ROOTS.CLIENTS}/verify`,
    verifyMail: `${ROOTS.CLIENTS}/verify-email`,
    kyc: `${ROOTS.CLIENTS}/questionnaire`,
    profile: `${ROOTS.CLIENTS}/profile`,
    unnax: `${ROOTS.CLIENTS}/unnax`,
    payment: `${ROOTS.CLIENTS}/payment`,
    paySuccess: `${ROOTS.CLIENTS}/pay-aproved`,
    payError: `${ROOTS.CLIENTS}/pay-error`,
    payPage: `${ROOTS.CLIENTS}/payments`,

  
  },
  // AGENTS
  agents: {
    root: ROOTS.AGENTS,
    client: {
      select: (id) => `${ROOTS.AGENTS}/client/${id}`,
    },
  },
};
