import PropTypes from 'prop-types';
import esLocale from 'date-fns/locale/es';
import { formatDistanceToNowStrict } from 'date-fns';
import { useSocket } from 'src/auth/context/socket-context';
import { useRouter } from 'src/routes/hooks';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
// utils
// components
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------
export default function NotificationItem({
  notification,
  setChatOpen,
  position,
  setNotifications,
  open,
}) {
  const { markReadNotifications } = useSocket();
  const router = useRouter();

  const handleMarkRead = () => {
    markReadNotifications([notification]);
    open.onFalse();
    setNotifications((prevNotifications) => {
      const newNotifications = prevNotifications.toReversed();
      newNotifications[position].read = true;
      return newNotifications;
    });
  };

  const renderAvatar = (
    <ListItemAvatar>
      <Avatar
        src="https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg"
        sx={{ bgcolor: 'background.neutral' }}
      />
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={reader(notification.title)}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: 'currentColor',
                mx: 0.5,
                borderRadius: '50%',
              }}
            />
          }
        >
          {formatDistanceToNowStrict(new Date(notification.created), {
            addSuffix: true,
            locale: esLocale,
          })}
        </Stack>
      }
    />
  );

  const messageAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button
        size="small"
        variant="contained"
        color='primary'
        onClick={() => {
          handleMarkRead();
          setChatOpen(true);
        }}
      >
        Ver
      </Button>
    </Stack>
  );

  const documenttAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button
        size="small"
        variant="contained"
        color='primary'
        onClick={() => {
          handleMarkRead();
          router.push(paths.clients.files);
        }}
      >
        Ver
      </Button>
    </Stack>
  );

  const updateErrortAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button
        size="small"
        variant="contained"
        color='primary'
        onClick={() => {
          handleMarkRead();
        }}
      >
        Aceptar
      </Button>
    </Stack>
  );

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }}>
        {renderText}
        {notification.type === 'message' && messageAction}
        {notification.type === 'document' && documenttAction}
        {notification.type === 'update_error' && updateErrortAction}

      </Stack>
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
  setChatOpen: PropTypes.func,
  position: PropTypes.number,
  setNotifications: PropTypes.func,
  open: PropTypes.object,
};

// ----------------------------------------------------------------------

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  );
}
