import { useState, useEffect, useRef } from 'react';
// @mui
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
// components
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import ClientService from 'src/services/client';
import { useAuthContext } from 'src/auth/hooks';
import { Stack } from '@mui/material';
import { ROLES } from 'src/_mock/_roles';

// ----------------------------------------------------------------------

export default function KYCSnackbar() {
  const router = useRouter();
  const { user } = useAuthContext();
  const [openAlertKYC, setOpenAlertKYC] = useState(false);
  const userRef = useRef(user);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userRef.current && userRef.current.role === ROLES.CLIENT) {
        ClientService.validateSolution()
          .then((response) => {
            if (!response) {
              setOpenAlertKYC(true);
            }
          })
          .catch((error) => console.error('Error fetching client KYCSnackbar:', error));
      }
    }, 120000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Snackbar
      open={openAlertKYC}
      onClose={() => setOpenAlertKYC(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        severity="warning"
        color="primary"
        sx={{ width: 1 }}
        action={
          <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
            <Button
              onClick={() => setOpenAlertKYC(false)}
              color="primary"
              size="small"
              variant="outlined"
            >
              Más tarde
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => router.push(paths.clients.kyc)}
              sx={{
                bgcolor: 'primary.dark',
              }}
            >
              Responder
            </Button>
          </Stack>
        }
      >
        Recuerda responder el cuestionario
      </Alert>
    </Snackbar>
  );
}
